#contact {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;

    .left-wrap {
        margin-right: 4rem;
    
        .img-wrap {
            margin-top: 2rem;
    
            img {
                border-radius: 100px;
                max-width: 500px;
            }

            @media (max-width: 1000px) {
                img { max-width: 350px;}
            }
        }
    }

    @media (max-width: 800px) {
        .left-wrap { display: none; }
    }

    .right-wrap {
        h1 {
            font-family: "Joules Hand";
            font-size: 3rem;
            font-weight: 4;
        }

        form {
            input, textarea {
                padding: 5px 10px;
                font-size: 1.2rem;
                width: 400px;
                margin: 5px;
            }

            .error {
                font-size: 12px;
                text-align: left;
                padding-left: 15px;
                padding-bottom: 5px;
                color: #E94B5F;
            }

            @media(min-width: 1200px) {
                input, textarea {
                    width: 500px;
                }
            }

            @media(max-width: 500px) {
                input, textarea {
                    width: 280px;
                    font-size: 1rem;
                }
            }
        }
    }
}

@media (max-width: 750px) {
    #contact { padding: 2rem 1rem; }
}