#not-found {
    height: 60vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;

    h1 {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        font-size: 3rem;

        .h1 { font-size: 8rem; margin-bottom: 2rem; }
    }

    .btn-wrap { margin-top: 2rem; padding: 2rem;}
}

@media(max-width: 1400px) {
    #not-found {
        padding: 4rem 1rem;
        h1  { font-size: 2rem; .h1 {font-size: 5rem;}}
    }
}

@media(max-width: 750px) {
    #not-found {
        padding: 4rem 1rem;
        h1  { font-size: 1.2rem; .h1 {font-size: 5rem;}}
    }
}