#press {
    border: solid 8px #000;
    background: #E94B5F;
    
    .grid {
        max-width: 1100px;
        margin: auto;
        display: -ms-grid;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));

        .img-wrap {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
                -ms-flex-pack: center;
                    justify-content: center;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            margin: 4rem 0;

            img {
                -webkit-transition: all ease .5s;
                -o-transition: all ease .5s;
                transition: all ease .5s;
                -webkit-transition: all ease .2s;

                &:hover {
                    -ms-transform: scale(1.1) rotate(-4deg);
                        transform: scale(1.1) rotate(-4deg);
                    -webkit-transform: scale(1.1) rotate(-4deg);
                    -o-transition: all ease .5s;
                    transition: all ease .5s;
                    -webkit-transition: all ease .5s;
                }

                @media (max-width: 500px) {
                    &:nth-child(1) { 
                        max-width: 180px;
                    }
                }
            }   
            
            @media (max-width: 500px) {
                &:nth-child(1) img { 
                    width: 100px;
                }

                &:nth-child(2) img { 
                    max-width: 150px;
                }

                &:nth-child(3) img { 
                    max-width: 90px;
                }

                &:nth-child(4) img { 
                    max-width: 200px;
                }

                &:nth-child(5) img { 
                    max-width: 130px;
                }

                &:nth-child(6) img { 
                    max-width: 110px;
                }
            }
        }
    }
}