#about {
    .wrap {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        
        .img-wrap {
            width: 50%;
            img {
                border-radius: 100px;
                max-height: 95vh;
                max-width: 95%;
            }
        }
        
        .cards-wrap {
            width: 50%;
            margin: auto;
            padding: 2rem;

            .text-wrap {
                min-height: 380px;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                    -ms-flex-align: center;
                        align-items: center;
                
                .text {
                    font-size: 1.4rem;
                    text-align: left;
                    opacity: 0;
                }

                @media (min-width: 1400px) {
                    .text { font-size: 2rem; }
                }

                .text.active {
                    opacity: 1;
                    -webkit-transition-duration: 1.4s;
                         -o-transition-duration: 1.4s;
                            transition-duration: 1.4s;
                }
            }

            .arrow {
                font-size: 2.4rem;
                position: relative;
                margin-top: 2rem;

                &:hover path{
                    color: #E94B5F;
                    -o-transition: all ease 0.2s;
                    transition: all ease 0.2s;
                    -webkit-transition: all ease 0.2s;
                }
            }

            .arrow.active {
                opacity: 1;
                cursor: pointer;
                -webkit-transition-duration: 1s;
                     -o-transition-duration: 1s;
                        transition-duration: 1s;
            }

            .left-arrow { right: 2rem; } 
            .right-arrow { left: 2rem; }

            #restart { 
                font-size: 2.4rem; 
                left: 1.5rem; left: 2rem; 
                -webkit-transform: scale(1.18); 
                -ms-transform: scale(1.18); 
                transform: scale(1.18);
            }
        }
    }

    @media (max-width: 750px) {
        .wrap { 
            -webkit-box-orient: vertical; 
            -webkit-box-direction: normal; 
                -ms-flex-direction: column; 
                    flex-direction: column; 
            
            .img-wrap { 
                width: 100%; 
                img {
                    max-height: 500px;
                }
            }

            .cards-wrap { 
                width: auto; 
                .text-wrap { 
                    min-height: 200px; 
                    -webkit-box-align: start; 
                        -ms-flex-align: start; 
                            align-items: flex-start;
                }
            }
        }
    }
}