#footer {
    width: 100%;
    background: #636363;

    footer {
        max-width: 1200px;
        margin: auto;
        text-align: center;
        padding: 4rem 4rem 2rem;

        @media (max-width: 500px) {
            padding: 4rem 1rem;
        }

        .wrap {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: justify;
                -ms-flex-pack: justify;
                    justify-content: space-between;

            .column {
                display: block;
                text-align: left;
            }

            .left {
                h3 a {
                    font-family: 'Joules Hand';
                    color: #fff;
                    font-weight: 3;
                    font-size: 2.3rem;
                    &:hover { text-decoration: none; }
                }
                
                a {
                    color: #FBE4D6;
                    font-size: 1rem;
                    &:hover { text-decoration: underline; }
                }

                @media(max-width: 400px) {
                    h3 a { font-size: 2rem; }
                }
            }

            .right {
                p {
                    color: #fff;
                    font-size: 1.3rem;
                }

                .subscription_capture {
                    .fieldset {
                        margin-top: 1rem;

                        input {
                            padding: .5rem .7rem;
                            font-size: 1rem;
                            width: 280px;
                            color: #E94B5F;
                        }

                        input::-webkit-input-placeholder {
                            color: #df8a95
                        }

                        input::-moz-placeholder {
                            color: #df8a95
                        }

                        input:-ms-input-placeholder {
                            color: #df8a95
                        }

                        input::-ms-input-placeholder {
                            color: #df8a95
                        }

                        input::placeholder {
                            color: #df8a95
                        }
                    }
                    
                    button {
                        margin-top: 1rem;
                    }
                }
            }
        }

        @media(max-width: 750px) {
            .wrap { 
                -webkit-box-orient: vertical; 
                -webkit-box-direction: normal; 
                    -ms-flex-direction: column; 
                        flex-direction: column;
                
                .right { 
                    margin-top: 4rem; 
                    p { font-size: 1rem;}

                    .subscription_capture {
                        .fieldset {
                            input {
                                width: 260px;
                            }
                        }
                    }
                }
            }
        }

        .copyright_content {
            margin-top: 4rem;

            h4 {
                color: #fff;
                font-size: 1rem;
                margin-bottom: .5rem;

                a {
                    color:#FBE4D6;
                    font-size: 1rem;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            small {
                color: #fff;
                font-size: .8rem;
            }
        }
    }
}