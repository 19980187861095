.header-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 100%;
    height: 100%;
    
    header {
        background: #000;
        background-image: url('../images/banner.jpg');
        background-size: cover;
        min-height: 100vh;
        scroll-snap-align: center;
        text-align: center;
    
        h1 {
            font-family: 'Joules Hand';
            color: #fff;
            -ms-transform: translateY(35vh);
                transform: translateY(35vh);
            -webkit-transform: translateY(35vh);
            font-size: 6rem;
            font-weight: 3;
        } 
        
        @media(min-width: 1100px) { h1 { font-size: 9rem; }}
        @media(max-width: 750px) { h1 { font-size: 4rem; }}
        @media(max-width: 500px) { h1 { font-size: 3.2rem; }}
        @media(max-width: 400px) { h1 { font-size: 2.4rem; }}


        nav {
            -ms-transform: translateY(35vh);
            transform: translateY(35vh);
            -webkit-transform: translateY(35vh);
            width: 100%;
            text-align: center;

            ul {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -ms-flex-pack: distribute;
                justify-content: space-around;
                max-width: 600px;
                margin: auto;
                
                li {
                    button, a {
                        font-size: 1.8rem;
                        color: #fff;
                        z-index: 1;

                        &:hover {
                            text-decoration: underline;
                            cursor: pointer;
                            -o-transition: all ease 0.3s;
                            transition: all ease 0.3s;
                            -webkit-transition: all ease 0.3s;
                        }
                    }
                    @media(max-width: 750px) { button, a { font-size: 1.2rem; }}
                    @media(max-width: 400px) { button, a { font-size: 1rem;}}
                }
            }
        }

        @media(max-width: 750px) { nav { 
            -ms-transform: translateY(37vh);
            transform: translateY(37vh);
            -webkit-transform: translateY(37vh);
        }}
    }
    
    .overlay {
        position: absolute;
        background: rgba(0, 0, 0, 0.1);
        opacity: 1;  
        width: 100%;
        height: 100vh;
        pointer-events: none;
    }
}