#portfolio {
    background: #000;
    z-index: -2;
    
    h2 {
        color: #fff;
        margin-bottom: 2rem;
    }

    #portfolio_slider {
        position: relative;
        top: 2rem;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-pack: end;
            -ms-flex-pack: end;
                justify-content: flex-end;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        min-height: 100vh;

        @media (max-width: 400px) {
            min-height: 850px;
        }

        .arrow {
            position: absolute;
            top: 50%;
            font-size: 3rem;
            -webkit-user-select: none;
               -moz-user-select: none;
                -ms-user-select: none;
                    user-select: none;
            z-index: 4;
            cursor: pointer;
            color: #fff;

            path {
                color: #fff;
            }

            &:hover {
                color: #E94B5F;
                -o-transition: all ease 0.2s;
                transition: all ease 0.2s;
                -webkit-transition: all ease 0.2s;

                path {
                    color: #E94B5F;
                    -o-transition: all ease 0.2s;
                    transition: all ease 0.2s;
                    -webkit-transition: all ease 0.2s;
                }
            }
        }

        .left-arrow { left: 3rem; } 
        .right-arrow { right: 3rem; }

        @media(min-width: 1400px) {
            .left-arrow { left: 18rem; } 
            .right-arrow { right: 18rem; }
        } 
        @media(max-width: 750px) {
            .left-arrow { left: .5rem; } 
            .right-arrow { right: .5rem; }
        }

        .slide-wrap {
            .slide {
                opacity: 0;
                -o-transition: 1s ease all;
                transition: 1s ease all;
                -webkit-transition: 1s ease all;
            }
    
            .slide.active {
                opacity: 1;
                -ms-transform: scale(1.08);
                    transform: scale(1.08);
                -webkit-transform: scale(1.08s);
                -webkit-transition-duration: 1s;
                     -o-transition-duration: 1s;
                        transition-duration: 1s;
                -webkit-transform: 1s;
                z-index: 2;

                img {
                    max-width: 330px;
                }

                @media (max-width: 400px) {
                    img { max-width: 280px; }
                }
                
            }
        }

        .bottom-que {
            margin: 6rem 0;
            padding-bottom: 2rem;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: baseline;
                -ms-flex-align: baseline;
                    align-items: baseline;

            .slide.active {
                -ms-transform: scale(1.1);
                    transform: scale(1.1);
                -webkit-transform: scale(1.1);
                -o-transition: all ease 1s;
                transition: all ease 1s;
                -webkit-transition: all ease 1s;
            }

            @media (max-width: 600px) {
                img { width: 70px; }
            }

            @media (max-width: 400px) {
                img { width: 50px; }
            }
        }

        .left-que { left: -15rem; }
        .right-que { right: -15rem; }
    }
}