#awards {
    background: #FBE4D6;
    z-index: -2;
    height: 700px;

    h2 {
        margin-bottom: 5rem;
    }

    @media (max-width: 500px) { 
        height: 400px;
        h2 { margin-bottom: 2rem;}
        .awards_slider { height: 300px; } 
    }


    .awards_slider {
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;

        @media (min-width: 501px) { min-height: 506px; }

        .arrow {
            position: absolute;
            top: 50%;
            font-size: 3rem;
            -webkit-user-select: none;
               -moz-user-select: none;
                -ms-user-select: none;
                    user-select: none;
            z-index: 4;
            cursor: pointer;
            color: #fff;

            path {
                color: #fff;
            }

            &:hover {
                color: #E94B5F;
                -o-transition: all ease 0.2s;
                transition: all ease 0.2s;
                -webkit-transition: all ease 0.2s;

                path {
                    color: #E94B5F;
                    -o-transition: all ease 0.2s;
                    transition: all ease 0.2s;
                    -webkit-transition: all ease 0.2s;
                }
            }
        }

        .left-arrow { left: 3rem; } 
        .right-arrow { right: 3rem; }

        @media(min-width: 1400px) {
            .left-arrow { left: 5rem; } 
            .right-arrow { right: 5rem; }
        } 
        @media(max-width: 750px) {
            .left-arrow { left: .5rem; } 
            .right-arrow { right: .5rem; }
        }

        @media(max-width: 400px) {
            .arrow { font-size: 2.8rem; }
        }

        .slides-wrap {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            position: absolute;

            .slide {
                -webkit-transition: 1s ease all;
                     -o-transition: 1s ease all;
                        transition: 1s ease all;

                        max-width: 500px;
                        img { width: 100%; }
            }
    
            .slide.active {
                -o-transition-duration: 1;
                   transition-duration: 1s;
                -webkit-transition-duration: 1s;
                -ms-transform: scale(1.08);
                    transform: scale(1.08);
                -webkit-transform: scale(1.08);
                z-index: 2;
            }

            .slide-que {
                position: relative;
                max-width: 500px;
                img { width: 100%; }
            }

            .left-que { left: -12rem; }
            .right-que { right: -12rem; }

            @media(max-width: 1000px) {
                .left-que { left: -6rem; }
                .right-que { right: -6rem; }
            }

            @media (max-width: 750px) {
                .slide { max-width: 350px;
                    img { width: 100%; }}

                .slide-que { 
                    max-width: 350px;
                    img { width: 100%; }
                }
                .left-que { left: -4rem; }
                .right-que { right: -4rem; }
            }

            @media (max-width: 500px) {
                .slide { 
                    max-width: 280px;
                    opacity: 0;
                    -o-transition: 1s ease all;
                    transition: 1s ease all;
                    -webkit-transition: 1s ease all;

                    img { width: 100%; }
                }
                .slide.active {
                    opacity: 1;
                    -ms-transform: scale(1.08);
                        transform: scale(1.08);
                    -webkit-transform: scale(1.08s);
                    -webkit-transition-duration: 1s;
                        -o-transition-duration: 1s;
                            transition-duration: 1s;
                    -webkit-transform: 1s;
                }

                .slide-que { 
                    max-width: 280px;
                    display: none;
                }
                .left-que { left: 0; display: none; opacity: 0; }
                .right-que { right: 0; display: none; opacity: 0; }
            }
        }
    }
}

@media (max-width: 400px) {
    #awards { 
        height: 500px; 
        h2 { margin-bottom: 0;}
        .awards_slider { min-height: 450px; }
    }
}