#thankyou {
    min-height: 60vh;
    background: #FBE4D6;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
            padding: 2rem;

            h1 {
                text-align: center;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                    -ms-flex-direction: column;
                        flex-direction: column;
                span {
                    margin: .8rem auto; 
                    font-size: 1.5rem;
                }
            }

            .btn-container {
                margin: 2rem auto;
            }
}