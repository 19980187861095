* {
  padding: 0;
  margin: 0;
  font-family: "Joules Serif";
  list-style-type: none;
  -webkit-transition: all ease-out .5s;
  -o-transition: all ease-out .5s;
  transition: all ease-out .5s;
  -webkit-transition: all ease-out .2s;
}

a { text-decoration: none; }

button {
  outline: none;
  border: none;
  background: none;
}

h2 {
  font-size: 3.5rem;
  margin-bottom: 2rem;
}

section {
  padding: 4rem;
  text-align: center;
}

.hidden { display: none; }

.opacity-0 { opacity: 0; }

.btn {
  background: #E94B5F;
  color: #fff;
  border-radius: .2rem;
  padding: .5rem 1.2rem;
  font-size: 1.2rem; 
  cursor: pointer;

  &:hover { 
    background: #000; 
    -o-transition: all ease .15s; 
    transition: all ease .15s;
    -webkit-transition: all ease .15s; 
  }
}

@media(max-width: 750px) {
  h2 { font-size: 3rem; }
  section { padding: 4rem 1rem; }
}

@media(max-width: 500px) {
  h2 { font-size: 2.4rem; }
  section { padding: 4rem .2rem; }
  p, a , button, label, input, .btn { font-size: 1rem; }
}

@font-face {
  font-family: "Joules Hand";
  src: url('../fonts/Joules et Jaques Hand.otf');
}

@font-face {
  font-family: "Joules Hand Thick";
  src: url('../fonts/Joules et Jaques Hand-thicker.otf');
}

@font-face {
  font-family: "Joules Serif";
  src: url('../fonts/Joules et Jaques Serif.otf');
}